import React from 'react'
import { useSpring, animated } from 'react-spring'

import Layout from '../components/layout'
export const FitKidz4FunJobs = () => {
  const springOpacity = useSpring({
    from: { opacity: 0, transform: 'translateX(90px)' },
    to: { opacity: 1, transform: 'translateX(0)' },
  })
  return (
    <Layout seoTitle="Jobs at Fit Kidz 4 Fun">
      <animated.div style={springOpacity} className="relative bg-gray-50">
        <div className="lg:absolute lg:inset-0">
          <div className="lg:absolute lg:inset-y-0 lg:left-0 lg:w-1/2">
            <img
              className="h-56 w-full object-cover lg:absolute lg:h-full"
              src="https://images.unsplash.com/photo-1542323228-002ac256e7b8?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1567&q=80"
              alt=""
            />
          </div>
        </div>
        <div className="relative pt-12 pb-16 px-4 sm:pt-16 sm:px-6 lg:px-8 lg:max-w-7xl lg:mx-auto lg:grid lg:grid-cols-2">
          <div className="lg:col-start-2 lg:pl-8">
            <div className="text-base max-w-prose mx-auto lg:max-w-lg lg:ml-auto lg:mr-0">
              <div className="bg-gray-50 shadow-lg overflow-hidden sm:rounded-lg">
                <div>
                  <div className="px-4 py-5 border-b border-gray-200 sm:px-6">
                    <h3 className="text-lg leading-6 font-medium text-gray-800">
                      Position Listing
                    </h3>
                  </div>
                  <div className="px-4 py-5 sm:p-0">
                    <dl>
                      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                        <dt className="text-sm leading-5 font-medium text-gray-500">
                          Role
                        </dt>
                        <dd className="mt-1 text-sm leading-5 text-gray-500 sm:mt-0 sm:col-span-2">
                          Assistant Trainer
                        </dd>
                      </div>
                      <div className="mt-8 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5">
                        <dt className="text-sm leading-5 font-medium text-gray-500">
                          Job Type
                        </dt>
                        <dd className="mt-1 text-sm leading-5 text-gray-500 sm:mt-0 sm:col-span-2">
                          Part-time, Temporary
                        </dd>
                      </div>
                      <div className="mt-8 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5">
                        <dt className="text-sm leading-5 font-medium text-gray-500">
                          Schedule
                        </dt>
                        <dd className="mt-1 text-sm leading-5 text-gray-500 sm:mt-0 sm:col-span-2">
                          <div className="grid grid-cols-2 gap-4">
                            <span className="shadow-md p-1 rounded text-gray-500">
                              mondays
                            </span>
                            <span className="shadow-md p-1 rounded text-gray-500">
                              2:45pm - 4:45pm
                            </span>
                            <span className="shadow-md p-1 rounded text-gray-500">
                              tuesdays
                            </span>
                            <span className="shadow-md p-1 rounded text-gray-500">
                              2:45pm - 4:45pm
                            </span>
                            <span className="shadow-md p-1 rounded text-gray-500">
                              thursdays
                            </span>
                            <span className="shadow-md p-1 rounded text-gray-500">
                              2:45pm - 4:45pm
                            </span>
                            <span className="shadow-md p-1 rounded text-gray-500">
                              fridays
                            </span>
                            <span className="shadow-md p-1 rounded text-gray-500">
                              1:45pm - 3:45pm
                            </span>
                          </div>
                        </dd>
                      </div>
                      <div className="mt-8 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5">
                        <dt className="text-sm leading-5 font-medium text-gray-500">
                          Background Check
                        </dt>
                        <dd className="mt-1 text-sm leading-5 text-gray-500 sm:mt-0 sm:col-span-2">
                          required
                        </dd>
                      </div>
                      <div className="mt-8 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5">
                        <dt className="text-sm leading-5 font-medium text-gray-500">
                          Key Task
                        </dt>
                        <dd className="mt-1 text-sm leading-5 text-gray-500 sm:mt-0 sm:col-span-2">
                          Participate in the development and implementation of
                          program activities and make suggestions for
                          improvement when necessary
                        </dd>
                      </div>
                      <div className="mt-8 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5">
                        <dt className="text-sm leading-5 font-medium text-gray-500">
                          Ideal Candidate
                        </dt>
                        <dd className="mt-1 text-sm leading-5 text-gray-500 sm:mt-0 sm:col-span-2">
                          <div className="grid grid-cols-2 gap-3">
                            <div className="rounded shadow-md p-2 md:p-1 text-gray-500">
                              Willing to assist in direction, supervision, and
                              organization of kids programs.
                            </div>
                            <div className="rounded shadow-md p-2 md:p-1 text-gray-500">
                              Provides emergency care and treatment as needed
                            </div>
                            <div className="rounded shadow-md p-2 md:p-1 text-gray-500">
                              Attend required staff meetings and pre-camp
                              training
                            </div>
                            <div className="rounded shadow-md p-2 md:p-1 text-gray-500">
                              Readiness and willing to jump right in as a coach.
                            </div>
                            <div className="rounded shadow-md p-2 md:p-1 text-gray-500">
                              Ability to be creative on the spot.
                            </div>
                            <div className="rounded shadow-md p-2 md:p-1 text-gray-500">
                              Flexible and mobile.
                            </div>
                          </div>
                        </dd>
                      </div>
                    </dl>
                  </div>
                </div>
              </div>
              <div className="flex justify-center">
                <a
                  href="mailto:Fitkidz@cortizfitness.com?subject=Application for assistant trainer"
                  title="Application for Assistant Trainer"
                  className="mt-4 shadow inline-flex items-center px-6 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-accent-400 hover:bg-accent-500 focus:outline-none focus:border-accent-500 focus:shadow-outline-indigo active:bg-accent-500"
                >
                  Apply now by emailing Fitkidz@cortizfitness.com
                </a>
              </div>
            </div>
          </div>
        </div>
      </animated.div>
    </Layout>
  )
}

export default FitKidz4FunJobs
